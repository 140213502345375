<template>
  <div>
    <!-- Top Domain Details -->
    <div class="vx-row">
      <div class="vx-col w-full">
        <div class="domain-info mb-8">
          <!-- Date Picker -->
          <date-range-picker class="review-date" :fromDateValue="fromDate" :toDateValue="toDate" @inputFromDate="(newFromDate) => {fromDate = newFromDate}" @inputToDate="(newToDate) => {toDate = newToDate}"></date-range-picker>         
          <vs-button  type="filled" @click="getEmailTrackActivityStream()" class="small-btn bg-actionbutton">{{ LabelConstant.buttonLabelRunQuery }}</vs-button>
        </div>
      </div>
    </div>
    <vx-card>
        <div class="table-title">
            <h2>Email Activity Stream</h2>  
            <div class="actions-button">              
              <div class="actions-button">
                  <vx-tooltip color="actionbutton" text="EXCEL" class="float-left">
                      <vs-button class="bg-actionbutton" type="filled">
                        <download-excel
                          class="btn btn-default"
                          :data="excelData"
                          :exportFields="excelFields"
                          worksheet="Email Activity Stream"
                          name="Email Activity Stream.xls"
                        >
                          <img src="@/assets/images/excel.svg" />
                        </download-excel>
                      </vs-button>
                  </vx-tooltip>
              </div>
            </div>
        </div>

        <div class="common-table">      
            <div class="table-row-select">
               <vs-select label="Show Entries" v-model="perPage" class="show-select" autocomplete>
                  <vs-select-item
                  :key="index"
                  :value="item.value"
                  :text="item.text"
                  v-for="(item,index) in recordList"
                  />
               </vs-select>
            </div>
            <vs-table id="tableDataEmailActivity" ref="tableDataEmailActivity" :data="emailActivityData" search :max-items="perPage" pagination>
                  <template slot="thead">
                    <vs-th></vs-th>
                    <vs-th sort-key="sortableCreatedDate">Activity Date</vs-th>
                    <vs-th sort-key="Activity">Activity</vs-th>
                    <vs-th sort-key="Email_To">Email To</vs-th>
                    <vs-th sort-key="Subject">Subject</vs-th>
                  </template>
                  <template slot-scope="{data}">
                    <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                        <vs-td>
                            <vs-button
                              v-if="tr.Activity == 'Email Opened'"
                              @click="isEmailActivityData=true, getEmailDetailByOpenId(tr.Id)"                          
                              title="View Detail"
                              type="filled"
                              icon-pack="feather"
                              icon="icon-search"
                              class="mr-2 float-left"
                              color="primary"
                            ></vs-button>
                            <vs-button
                              v-if="tr.Activity == 'Email Sent'"
                              @click="isEmailActivityData=true, getEmailDetailById(tr.Id)"                        
                              title="View Detail"
                              type="filled"
                              icon-pack="feather"
                              icon="icon-search"
                              class="mr-2 float-left"
                              color="primary"
                            ></vs-button>
                        </vs-td>
                        <vs-td :data="tr.Date_Formatted">
                              {{tr.Date_Formatted}}
                        </vs-td>
                        <vs-td :data="tr.Activity">
                              <vs-chip color="success" v-if="tr.Activity == 'Email Opened'">Email Opened</vs-chip>
                              <vs-chip color="warning" v-if="tr.Activity == 'Email Sent'">Email Sent</vs-chip>
                        </vs-td>
                        <vs-td :data="tr.Email_To">
                              {{tr.Email_To}}
                        </vs-td>
                        <vs-td :data="tr.Subject">
                              {{tr.Subject}}
                        </vs-td>
                    </vs-tr>
                  </template>
            </vs-table>
            <p class="table-data-entries-details" v-if="!isSearch && isTableRecordEntriesDisplay">Showing {{showingDataFrom}} to {{showingDataTo}} of {{emailActivityData.length|tableRecordNumberFormatter}} entries</p>
            <p class="table-data-entries-details" v-if="isSearch && isTableRecordEntriesDisplay">Showing {{showingDataFrom}} to {{showingSearchedDataTo}} of {{totalSearchedData|tableRecordNumberFormatter}} entries (filtered from {{emailActivityData.length|tableRecordNumberFormatter}} entries)</p>
            <p class="table-data-entries-details" v-if="!isTableRecordEntriesDisplay">Showing 0 to 0 of 0 entries </p> 
        </div>
        <!--Table Select Row-->
		</vx-card>
    <!-- Email Details Popup -->      
    <vs-popup class="company-details-modal close-open" title="Email Details" :active.sync="emailActivityPopupActive" v-if="isEmailActivityData"> 
        <vs-button
          @click="closeViewEmailActivityData()"
          color="secondary"
          type="filled"
          class="mb-8 float-right close-popup-btn"
        >{{ LabelConstant.buttonLabelClose }}</vs-button>
        <template>
            <div class="vx-row mb-3">
                <div class="vx-col w-full">                   
                    <ul class="account-summary mt-4 mb-2 md:mt-0">
                        <li class="pt-0 w-full md:w-1/2 mb-4 md:mb-0">
                            <h4><b>Date Sent :</b> {{viewEmailActivityData.Date_Created}}</h4>                            
                        </li>
                        <li class="pt-0 w-full md:w-1/2 mb-4 md:mb-0">
                            <h4><b>Email To :</b> {{viewEmailActivityData.Email_To}}</h4>
                        </li>
                        <li class="w-full md:w-1/2 mb-4 md:mb-0">
                            <h4><b>Subject :</b> {{viewEmailActivityData.Subject}}</h4>
                        </li>
                        <li class="w-full md:w-1/2 mb-4 md:mb-0">
                            <h4><b>Template :</b> {{viewEmailActivityData.Template_Name}}</h4>
                        </li>
                        <li class="w-full md:w-1/2">
                            <h4><b>Time To Open First :</b> {{viewEmailActivityData.Humanized_Time_To_Open}}</h4>
                        </li>
                    </ul>
                </div>
                <div class="vx-col w-full option-section border-0 mt-0">                    
                    <div class="vx-row">
                        <div class="vx-col w-full">
                            <vs-table :data="viewEmailActivityData.Activity_Stream">
                              <template slot="thead">
                                <vs-th>Date</vs-th>
                                <vs-th>Activity</vs-th>
                              </template>
                            <template slot-scope="{data}">
                            <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                                <vs-td :data="data[indextr].Date_Formatted">
                                  {{ data[indextr].Date_Formatted }}
                                </vs-td>
                                <vs-td :data="data[indextr].Activity">
                                  {{ data[indextr].Activity }}
                                </vs-td>
                            </vs-tr>
                            </template>
                        </vs-table>
                        </div>
                    </div>
                </div>
            </div>
        </template>
        <div class="float-right mt-6 mb-8">            
            <vs-button @click="closeViewEmailActivityData()" color="grey" type="border" class="ml-4 grey-btn" >{{ LabelConstant.buttonLabelClose }}</vs-button>
        </div>            
    </vs-popup>
  </div>
</template>

<script>
import VxCard from "@/components/vx-card/VxCard";

/* Filter */
import DateRangePicker from "../../components/filter/DateRangePicker";
import vSelect from 'vue-select'
import JsonExcel from "vue-json-excel";

export default {
  components: {
    VxCard,
    DateRangePicker, 
    vSelect,
    downloadExcel: JsonExcel,
  },
  data() {
    return {
      excelFields: {
          "Activity Data": "Date_Formatted",
          "Activity": "Activity",
          "Email To": "Email_To",
          "Subject": "Subject"
      },
      excelData: [],
      json_meta: [
          [
          {
              key: "charset",
              value: "utf-8"
          }
          ]
      ],

      //From Date
      fromDate: null,
      //To Date
      toDate: null,

      emailActivityData:[],

      perPage: 5,
      recordList: [
        {text: '5', value: 5},
        {text: '10', value: 10},
        {text: '25', value: 25},
        {text: '50', value: 50},
        {text: '100', value: 100}
      ],
      
      emailActivityPopupActive:false,
      viewEmailActivityData: {},
      isEmailActivityData: false,
    };
  },
  mounted() {
      let that = this;
      setTimeout(() => {
      document.querySelector("#tableDataEmailActivity .input-search").addEventListener("keyup", function() {
          that.excelData = that.filterExport(true);
          });
      }, 1000);
  },
  created(){
    this.setFromDate()
    this.setToDate()

    this.getEmailTrackActivityStream()
  },
  methods: {
    getEmailTrackActivityStream(){
      let getEmailTrackActivityStreamParams = {
        Date_Start: this.fromDate,
        Date_End: this.toDate
      }

      this.$vs.loading()
      this.axios.post("/ws/EmailTrackActivityStream/GetEmailTrackActivityStream", getEmailTrackActivityStreamParams).then(response => {
        let data = response.data;
        this.emailActivityData = data
        this.$refs.tableDataEmailActivity.currentx = 1
        this.$refs.tableDataEmailActivity.searchx = ""
        this.emailActivityData.map(function(manipulatedData) {
          manipulatedData.sortableCreatedDate = new Date(manipulatedData.Date_Created);
          return manipulatedData;
        });
        this.emailActivityData.sort(this.GetSortOrder("sortableCreatedDate", "DESC")); //Pass the attribute to be sorted on
        this.excelData = this.filterExport();
        this.$vs.loading.close();
				this.$vs.loading.close()
      }).catch(e => {
        this.$vs.loading.close()
        this.showError(e);
      });
    },
    filterExport(search = false) {
        let data = [];
        let emailActivityData = !search ? this.emailActivityData : this.$refs.tableDataEmailActivity.datax;

        for (var i = 0; i < emailActivityData.length; i++) {
            data.push({
                "Date_Formatted": emailActivityData[i].Date_Formatted,
                "Activity": emailActivityData[i].Activity,
                "Email_To": emailActivityData[i].Email_To,
                "Subject": emailActivityData[i].Subject
            });
        }
        return data;
    },
    getEmailDetailByOpenId(id){
      this.$vs.loading()
      this.axios.get("/ws/EmailTrackActivityStream/GetEmailDetailByOpenId?id="+id).then(response => {
        let data = response.data;
        this.viewEmailActivityData = data
        this.emailActivityPopupActive = true
        this.$vs.loading.close()
      }).catch(e => {
        this.$vs.loading.close()
        this.showError(e);
      });
    },
    getEmailDetailById(id){
      this.$vs.loading()
      this.axios.get("/ws/EmailTrackActivityStream/GetEmailDetailById?id="+id).then(response => {
        let data = response.data;
        this.viewEmailActivityData = data
        this.emailActivityPopupActive = true
        this.$vs.loading.close()
      }).catch(e => {
        this.$vs.loading.close()
        this.showError(e);
      });
    },
    closeViewEmailActivityData(){
      this.emailActivityPopupActive = false
      this.viewEmailActivityData = {}
      setTimeout(() => {
        this.isEmailActivityData = false
      }, 1000);
    },
  },
  computed: {
		isTableRecordEntriesDisplay(){
			if(this.emailActivityData.length > 0){
				if(this.$refs.tableDataEmailActivity.datax.length > 0){
					return true
				}else{
					return false
				}
			}else{
				return false
			}
		},
    isSearch: function () {
        if(this.emailActivityData.length > 0){
            if(this.$refs.tableDataEmailActivity.searchx == null || this.$refs.tableDataEmailActivity.searchx == ""){
                return false
            }else{
                return true
            }
        }
    },
    showingDataFrom: function () {
        if(this.emailActivityData.length > 0){
            return ((this.$refs.tableDataEmailActivity.currentx - 1) * this.perPage) + 1 
        }
    },
    showingDataTo: function () {
        if(this.emailActivityData.length > 0){
            let totalPages = this.$refs.tableDataEmailActivity.getTotalPages
            if(totalPages == this.$refs.tableDataEmailActivity.currentx){
                return ((this.$refs.tableDataEmailActivity.currentx - 1) * this.perPage) + this.$refs.tableDataEmailActivity.datax.length
            }else{
                return ((this.$refs.tableDataEmailActivity.currentx) * this.perPage)
            }
        }
    },
    totalSearchedData: function () {
        if(this.emailActivityData.length > 0){
            return this.$refs.tableDataEmailActivity.queriedResults.length
        }
    },
    showingSearchedDataTo: function () {
        if(this.emailActivityData.length > 0){
            let totalPages = this.$refs.tableDataEmailActivity.getTotalPagesSearch
            if(totalPages == this.$refs.tableDataEmailActivity.currentx){
                return ((this.$refs.tableDataEmailActivity.currentx - 1) * this.perPage) + this.$refs.tableDataEmailActivity.datax.length
            }else{
                return ((this.$refs.tableDataEmailActivity.currentx) * this.perPage)
            }
        }
    }
  }
};
</script>
